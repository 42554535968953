<template>
  <section>
    <div class="mx-5 mt-10 d-flex">
      <v-avatar tile size="60" color="success" />
      <div class="ml-4">
        <div class="text-h5">
          {{ `${student.firstname} ${student.lastname}` }}
        </div>
        <div class="caption">
          {{ `${student.email}` }}
        </div>
      </div>
    </div>
    <div class="ma-5">
      <Table @select="view" :progress="progress" :student="student" />
    </div>
    <Modal :course="course" :dialog="dialog" @close="modalClose" />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Table from "./ProfileTable.vue";
import Modal from "./LessonModal.vue";

export default {
  props: ["student", "progress"],
  data: () => ({
    dialog: false,
  }),
  components: {
    Table,
    Modal,
  },
  computed: {
    ...mapState("teacher", {
      course: (state) => state.course,
    }),
  },
  methods: {
    ...mapActions("teacher", ["lessonProgress"]),
    ...mapMutations("teacher", ["courseMutation"]),

    view(uuid) {
      this.lessonProgress({
        course_uuid: uuid,
        user_id: this.student.id,
      }).then(() => {
        this.dialog = true;
      });
    },
    modalClose() {
      this.dialog = false;
      this.courseMutation([]);
    },
  },
};
</script>