<template>
  <v-pagination
    v-model="currentPage"
    :length="lastPage"
    color="primary"
  ></v-pagination>
</template>

<script>
export default {
  props: ["store", "collection", "id"],
  computed: {
    currentPage: {
      get() {
        return this.$store.state[this.store][this.collection].current_page;
      },
      set(val) {
        return this.$store.commit(
          `${this.store}/${this.collection}PageMutation`,
          val
        );
      },
    },
    lastPage: {
      get() {
        return this.$store.state[this.store][this.collection].last_page;
      },
    },
  },
  methods: {
    paginate(pageNumber) {
      this.$store.dispatch(`${this.store}/showStudentProgress`, {
        page: pageNumber,
        count: this.$store.state[this.store].count,
        id: this.id,
      });
    },
  },
  watch: {
    currentPage(val) {
      this.paginate(val);
    },
  },
};
</script>