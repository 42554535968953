<template>
  <section>
    <v-row>
      <v-col cols="12" xl="9" lg="11">
        <v-sheet v-if="!loading" color="transparent">
          <div class="d-flex align-center">
            <v-btn
              @click="$router.back()"
              text
              style="text-transform: capitalize"
            >
              <div class="body-2">My Students</div>
            </v-btn>
            <v-icon class="mr-2">mdi-chevron-right</v-icon>
            <div class="body-2">Student Information</div>
          </div>
          <v-row>
            <v-col cols="12" lg="8">
              <Profile :student="student" :progress="progress" />
            </v-col>
            <v-col cols="12" lg="4"></v-col>
          </v-row>
        </v-sheet>
        <div v-else class="body-2">Loading please wait...</div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Profile from "./Profile.vue";

export default {
  components: {
    Profile,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("teacher", {
      student: (state) => state.students,
      progress: (state) => state.progress,
    }),
  },
  mounted() {
    const uuid = this.$route.params.uuid;
    const store = this.$store;

    store.dispatch("teacher/showStudentInfo", uuid);
    store
      .dispatch("teacher/showStudentProgress", {
        page: 0,
        count: 10,
        id: uuid,
      })
      .then(() => {
        this.loading = false;
      });
  },
  methods: {},
};
</script>