<template>
  <v-sheet class="px-5 pt-5">
    <div class="mx-3 mt-2 mb-5 d-flex justify-space-between">
      <div class="text-h6">Student Courses</div>
    </div>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left caption" width="10%">Image</th>
            <th class="text-left caption" width="50%">Course</th>
            <th class="text-left caption" width="50%">Progress</th>
            <th class=""></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in progress.data" :key="item.id">
            <td>
              <v-avatar color="success" tile size="36" v-if="!item.image" />
              <v-avatar color="success" tile size="36" v-else>
                <v-img
                  :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"
                />
              </v-avatar>
            </td>
            <td>
              <div class="body-2">{{ `${item.title.slice(0, 30)}` }}...</div>
            </td>
            <td class="body-2">
              <v-progress-linear
                color="success"
                rounded
                height="20"
                :value="item.progress"
                stream
              >
                {{ Math.ceil(item.progress) }}%
              </v-progress-linear>
            </td>
            <td class="body-2">
              <span @click="$emit('select', item.uuid)" class="info--text"
                >View</span
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <Paginate
      v-if="progress.total > 0"
      store="teacher"
      collection="progress"
      :id="$route.params.uuid"
      class="mt-5 col-sm-12 col-lg-8 mx-auto"
    />
  </v-sheet>
</template>

<script>
import Paginate from "./Pagination.vue";

export default {
  props: ["progress"],
  components: {
    Paginate,
  },
};
</script>

<style>
</style>