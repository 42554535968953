<template>
  <section>
    <v-dialog persistent max-width="540" v-model="dialog">
      <v-card rounded>
        <v-card-title
          class="d-flex justify-space-between align-center success white--text"
        >
          <div class="">Course Progress</div>
          <v-btn icon small @click="$emit('close')">
            <v-icon color="white" size="18">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="mt-4 mx-1">Lessons</div>

          <v-card
            flat
            class="
              secondary
              px-3
              py-2
              my-2
              d-flex
              justify-space-between
              align-center
            "
            v-for="item in course.lessons"
            :key="item.id"
          >
            <div class="d-flex flex-column">
              <div class="body-2 font-weight-bold">
                {{ item.title.slice(0, 30) }}
              </div>
              <div class="caption">
                {{
                  item.type == "Assessment"
                    ? `Assessment`
                    : `${item.topics.length} topic`
                }}
              </div>
            </div>
            <div class="">
              <v-progress-circular
                :rotate="90"
                :size="40"
                :width="7"
                :value="item.progress"
                color="success"
              >
                <small>{{ item.progress }}</small>
              </v-progress-circular>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  props: ["course", "dialog"],
};
</script>